.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
}

.popup-container {
    background-color: white;
    padding: 20px;
    border-radius: 25px;
}
/* 
.popup-message {
    margin-bottom: 10px;
}

.popup-actions {
    display: flex;
    justify-content: flex-end;
}

.popup-actions button {
    margin-left: 10px;
}
*/
/* 
@media (max-width: 576px) {
    .popup-actions button {
        margin-top: 10px;
    }
    .popup-actions {
        flex-direction: column;
      }
}  */