/* ProgressBar.css */

.progress-bar-container {
  width: 80%;
  padding: 10px;
}

.progress-bar {
  background-color: #e0e0e0;
  height: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  /* Change this to your desired progress color */
  transition: width 0.8s ease;
  /* Add a smooth transition effect */
}


.rotate {
  transform: rotate(360deg)
}


.gear1 {
  position: absolute;
  left: 35%;
  top:17%;
}

.gear2 {
  position: absolute;
  left: 50%;
  top: 10%;
}

.gear3 {
  position: absolute;
  left: 38%;
  top: 10%;
}